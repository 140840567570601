import React from "react";
import NavbarOne from "./siteTheme/NavbarOne";
import undermainimg from "../assets/images/settings/undermainimg.jpeg";
import "../assets/styledev.css";
import { useContextData } from "../core/context/index";

export default function Error404(props) {
  const { siteSettings } = useContextData();

  return (
    <div>
      <NavbarOne
        setTheme={props.setTheme}
        theme={props.theme}
        pagename={props.pagename}
      />
      <center>
        <div className="container">
          <div className="row min-vh-100">
            <div className="col-lg-12 mt-5 pt-5">
              <h1 className="my-5">Under Maintenance</h1>
              {/* <img src={undermainimg} alt="comming soon" className='img-fluid-css' /> */}
              <p className="textalignleft">
                <b>Hello Dear Community,</b>
                <br />
                <br />
                {/* from 30th Apr 24 until 1st May 24 */}
                🚧 We'd like to inform you that the Fibit Pro Exchange will be undergoing scheduled maintenance {(siteSettings && siteSettings.maintenanceDuration) ? siteSettings.maintenanceDuration : ""} for server upgrades.
                <br />
                <br />
                {/* If you need to Buy, Sell, Deposit, or Withdraw funds, please
                make sure to complete these transactions before 11 PM. During
                the maintenance period, these services will be temporarily
                unavailable. ⏳
                <br />
                <br /> */}
                We apologize for any inconvenience this may cause and appreciate
                your understanding. If you have any questions or concerns,
                please feel free to reach out to our support team. 📞
                <br />
                <br />
                Thank you for your cooperation. 👍
                <br />
                <br />
                <b>Best regards,</b>
                <br />
                <br />
                <b>Fibit Pro Exchange</b>
              </p>
            </div>
          </div>
        </div>
      </center>
    </div>
  );
}
